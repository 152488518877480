@import 'variables';

input#menutoggler {
    display:none;
}
body {
    padding-top:60px;
    //transition: padding-top 0.4s cubic-bezier(.5, -.75, .5, 1.75);
}
nav {
    width:100%;
    height:60px;
    left:0;
    right:0;
    top:0;
    bottom:calc(100% - 60px);
    position: fixed;
    background:rgba(255,255,255,0.9);
    transition: 0.4s cubic-bezier(.5, -.75, .5, 1.75);
    z-index:5;
    font-weight: 400;
    font-size:16px;
    box-shadow:0 0 10px -7px rgba(0,0,0,0.5);

    .wrapper {
        #logo {
            display:block;
            position: absolute;
            height:60px;
            line-height:60px;
            transition:0.4s cubic-bezier(.5, -.75, .5, 1.75);
            font-size:20px;
            font-weight:600;
            color:#000;
            padding:0 20px;
            svg {
                display:block;
                height:39px;
                margin:10px 0;
                transition:0.4s cubic-bezier(.5, -.75, .5, 1.75);
                .logo_font {
                    fill:#000;
                    opacity:0.8;
                }
            }
        }
        ul {
            margin:0;
            padding:0;
            list-style:none;
            position: fixed;
            top:60px;
            height:0;
            background:#fafafa;
            overflow-x: hidden;
            overflow-y: auto;
            left:100%;
            right:0;
            transition:0.4s cubic-bezier(.5, -.75, .5, 1.75);
            li {
                position: relative;
                list-style: none;
            }
            li a {
                display:block;
                height:60px;
                color:$color1;
                line-height:60px;
                padding:0 20px;
                text-decoration: none;
                transition:0.3s;
                cursor: pointer;
                transition:0.4s;
            }
            li a:hover, li.act > a {
                background:$color5;
                color:$color3;
            }
        }
    }
}

.admin-bar nav {
    top: 32px;
}

input:checked + nav {
    .wrapper {
        ul {
            height:calc(100% - 60px);
            left:0;
        }
    }

}

@media (min-width:1024px) {

    body { padding-top:100px; }

    nav {
        height:100px;
        bottom:calc(100% - 100px);
        background:rgba(255,255,255,0.9);
        .wrapper {
            #logo {
                display:block;
                float:left;
                height:100px;
                line-height:100px;
                font-size:40px;
                font-weight:600;
                color:$color3;
                cursor:pointer;
                svg {
                    display:block;
                    height:49px;
                    margin:25px 0;
                    transition:0.4s cubic-bezier(.5, -.75, .5, 1.75);
                    .logo_font {
                        fill:#000;
                        opacity:0.8;
                    }
                }
            }
            label {
                display:none;
            }
            ul {
                margin:0;
                padding:0;
                list-style:none;
                position: relative;
                height:99px;
                overflow: inherit;
                top:0;
                float:right;
                opacity:1;
                lefT:auto;
                right:auto;
                bottom:auto;
                transition:0;
                background:none;
                li {
                    position: relative;
                    float:left;
                }
                li a {
                    display:block;
                    height:100px;
                    color:#000;
                    line-height:100px;
                    padding:0 20px;
                    text-decoration: none;
                    transition:0.4s;
                    cursor: pointer;
                }
                li a:hover, li.current_page_item > a {
                    background:rgba($color5,1);
                    color:$color3;
                }
            }
        } 
    }

    body.home nav {
        background:rgba(0,0,0,0.1);
        .wrapper {
            #logo {
                svg {
                    .logo_font {
                        fill:#fff;
                        opacity:1;
                    }
                }
            }
            ul {
                li {
                    a {
                        color:$color3;
                    }
                    &.current_page_item > a, a:hover {
                    background:rgba($color3,0.1);
                    color:$color3;
                    }
                }
            }
        } 
    }
    body.home.whitenav nav {
        background:rgba(255,255,255,0.9);
        .wrapper {
            #logo {
                svg {
                    .logo_font {
                        fill:#000;
                        opacity:0.8;
                    }
                }
            }
            ul {
                li {
                    a {
                        color:#000;
                    }
                    &.current_page_item > a, a:hover {
                    background:rgba($color5,1);
                    color:$color3;
                    }
                }
            }
        } 
    }
    input:checked + nav {
        .wrapper {
            ul {
                height:calc(100% - 60px);
            }
        }

    }
    
}