@import 'variables';

html, body {
    margin:0;
    padding:0;
    background:#fafafa;
    height:100%;
}
main {
    min-height: calc(100% - 68px);
    position: relative;
}

.clear {
    clear:both;
    display: block;
}
.wrapper {
    max-width:1200px;
    margin:0 auto;
    &::after {
        content:"";
        displaY:block;
        clear:both;
    }
}
section {
    padding:60px 20px;
    &.contentarea {
    }
    &#sitemap {
        background:$color5;
    }
    img {
        max-width:100%;
        height:auto;
    }
    .sidebar {
        ul {
        margin: 0;
        padding: 0;
            li {
              padding: 0;
              list-style: none;
                a {
                  display: block;
                  line-height: 42px !important;
                  background: #FA9600;
                  color: #fff;
                  padding: 0 20px;
                  font-weight: 400;
                  font-size: 90%;
                }
            }
        }
    }
}



footer {
    padding:20px 0;
    background:$color1;
    color:rgba($color3,0.6);
    ul {
        padding:0;
        margin:0;
        text-align:right;
        li {
            display:inline;
            padding:0;
            list-style: none;
            a {
                color:rgba(255,255,255,0.7);
                &:hover {
                    color:#fff;
                }
            }
        }
    }
}
a.gallery {
  border: 5px solid rgba(0, 0, 0, 0.12);
  display: inline-block;
  position: relative;
}
a.gallery::after {
  content: "\f00e";
  position: absolute;
  top: 50%;
  left: 50%;
  line-height: 64px;
  color: rgba(255,255,255,0);
  text-align: center;
  font-family: fontawesome;
  transition: 0.3s;
  width: 64px;
  height: 64px;
  margin-top: -32px;
  margin-left: -32px;
  z-index: 3;
  font-size: 32px;
}
.gallery:hover::after {
  color: #fff;
}
.gallery::before {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
  background: rgba(0,0,0,0.0);
  transition: 0.3s;
}
.gallery:hover::before {
  background: rgba(0,0,0,0.3);
}