
header {
    position: relative;
    .item {
        position: relative;
        &::after {
            content: "";
            display: block;
            position: absolute;
            left: 0;
            top: 0;
            bottom: 0;
            right: 0;
            background: rgba(0,0,0,0);
            z-index: 1;
        }
        .overlay {
            position: absolute;
            top: 50%;
            left: 0%;
            right:0;
            overflow:hidden;
            transform: translate(0,-50%);
            color: #002E40;
            font-weight:400;
            width:100%;
            z-index:2;
            line-height: 180%;
            text-align: center;
            .anim {
                animation-duration: 0.7s;
                animation-delay: 0.7s;
                animation-fill-mode: forwards;
                opacity: 0;
                display: block;
                    background: rgba(255, 255, 255, 0.8);
                    padding: 20px 0px 20px;
                
                
            }
            p:first-child {
                margin:20px 0;
            }
        }
        img {
            display:block;
            width:100%;
        }
    }
    .active {
            .overlay {
                  .anim {
                        -webkit-animation-name: flipInXheader;
                        animation-name: flipInXheader;      
                }
            }
    }
    .owl-animated-out {
            .overlay {
                .anim {
                    -webkit-animation-name: flipOutXheader;    
                    animation-name: flipOutXheader;
                }
            }
    }
    .owl-dots {
        bottom: 0px;
        position: absolute;
        width: 100%;
        min-height: 36px;
        text-align: center;
        line-height: 34px;
    }
    .owl-dot {
        border: 2px solid #fff;
        display: inline-block;
        width: 16px;
        height: 16px;
        border-radius: 50%;
        margin: 0 8px;
        transition: 0.2s;
    }
    .owl-dot.active {
        background: #fff;
    }
}
@media (min-width:640px) {
    header {
        .item {
            .overlay {
                left: 50%;
                top: 50%;
                right:auto;
                transform: translate(-50%,-50%);
                background:none;
                width:auto;
                .anim {
                    padding: 50px 40px 20px;
                    
                }
            }
        }
    } 
}
@media (min-width:1024px) {
    header {
        .item {
            .overlay {
                left: 15%;
                .anim {
                    background: rgba(255, 255, 255, 0.8); 
                }
            }
        }
    } 
}